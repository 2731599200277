<template>
    <div class="cookies-policy">
        <h2>We love retro gaming!</h2>

        <p>Last update October 13, 2020</p>

        <p>
            Our mission is to find ways to keep retro gaming alive!<br>
            Although this is a very early stage of <a href="/about">anfient</a>, one day we hope it can grow and contribute to the retro community.<br>
        </p>

        <h2>Let's start</h2>
        <p>
            We don't know exactly how we going to contribute, but let's start with collecting data, data, and data...<br>
            We have currently collected information on nearly 40.000 (retro) games from 47 consoles.<br>
            This is just bare minimum information, but just enough to create some basic functionalities... we must start somewhere :)!
        </p>

        <h2>What do we have?</h2>
        <p>
            Right now its a super simple webApp<br>
        <ul>
            <li>You can register, login, set up a basic profile</li>
            <li>Send friend request, or rather unfriend your enemies</li>
            <li>Search in the game dataBase and setup game lists of games you want to play, playing, have played or finished</li>
            <li>Seeing what your friends are going to play, playing and finished</li>
            <li>An impression of the user interface</li>
            <li>Some artwork</li>
        </ul>
        </p>

        <h2>Where are we heading to?</h2>
        <p>
        <ul>
            <li>Gather more and more (retro) game information (reviews, walkthroughs, metaData)</li>
            <li>Create algorithms that will generate suggestions of which games you should play based on you lists</li>
            <li>Game rating system</li>
            <li>Add achievements for retro games</li>
        </ul>
        </p>
    </div>
</template>

<script>
export default {
  name: 'CookiesPolicy',
  components: {},
  props: {},
  mounted () {},
  data () {
    return {}
  },

  methods: {}
}
</script>

<style lang="scss">
@import '../../styles/variables.scss';

.cookies-policy {
    text-align: left;
}
</style>
